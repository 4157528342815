.card.register{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center; 
    height:fit-content;
    max-width: 500px;
    border-radius: '5px';
    padding-inline: 60px;
  }

  .FormTitle{
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    color: #3C4257
}