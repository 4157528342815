.arrow-button{
    border:0;
    margin-inline: 15px;
    font-size: 24px;
    background-color: #FFFFFF;
    font-stretch:narrower;
}

.select-button{
    border:0;
}

.text-button{
    border:0;
    padding-left: 5px;
    background-color: #FFFFFF;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
}

.datepicker-input-container {
    display: flex;
    align-items: center;
    position: relative;
}

.datepicker-input-container input {
  padding-right: 30px;
}

.datepicker-input-container .calendar-icon {
  position: absolute;
  right: 10px; /* margin from the right side for calendar icon*/
  cursor: pointer;
}

.react-datepicker{
  border: 1px solid rgb(211, 214, 216) !important;
}

.react-datepicker-wrapper{
  width: 95%;  /* width of input area of date-picker*/
  margin-block:10px; /* margin in vertical direction of input area of date-picker*/
}


.react-datepicker__week{
  display: flex;
  justify-content: space-between;
  margin-block: 10px;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  font-weight: 550;
}

.react-datepicker__header.react-datepicker__header--custom {
  background-color: #ffffff;
}

.react-datepicker__month-container {
  width: 380px;
}

.react-datepicker__children-container{
  width: 100% !important;
}
  
