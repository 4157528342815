#main {
    margin-top: 60px;
    margin-left: 200px;
    padding: 30px 20px;
    transition: all 0.3s;
}

/* .headerRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

.pageTitle{
    font-family: "Poppins";
    font-weight:800;
    font-style:normal;
    font-size: 26px;
    color: #2D2D2D;
}

 h4{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 16px;
    color: #2D2D2D;
}

.table-cardTitle{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 20px;
    color: #2D2D2D;
    margin-bottom: 20px;
}

.button-save{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 7px 15px 7px 15px;
    background-color: #81e0ef;
    border: 0;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
}

.button-save.active{
    background-color: #00A0B7;
}

