
.sidebar{
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 200px;
    z-index: 10;
    transition: all 0.3s;
    padding-left: 5px;
    padding-top: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    /* box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1); */
    background-color: #fff;
}

.sidebar-nav{
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav li{
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-item{
    margin-bottom: 5px;
}

.sidebar-nav .nav-item .vertical-border{
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    width: 3px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #00A2B9;
}

.sidebar-nav .nav-link{
    padding: 10px 15px;
    align-items: center;
    display: flex;
}

.nav-link{
    background-color: #fff;
}

.sidebar-nav .nav-link i{
    font-size: 16px;
    margin-right: 10px;
    color: #4154f1;
}

.itemText{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
}

