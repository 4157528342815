/* card titel */
.card-title{
    width: 50%;
    font-family: "Poppins";
    font-weight:500;
    font-style:normal;
    font-size: 14px;
    color: #72777B;
}

.yearlyOuter{
    font-family: "Poppins";
    font-weight:700;
    font-style:normal;
    font-size: 20px;
    color: #2D2D2D;
}

.yearlyInner{
    font-family: "Poppins";
    font-weight:800;
    font-style:normal;
    font-size: 38px;
    color: #2D2D2D;
}

.btn.btn-light.btn-outline-dark:hover {
    background-color: transparent;
    color: #000;
}

.btn{
    border-radius: 0;
    font-size: small;
}

.btn-secondary{
    background-color: #E7EAEE;
    color: #2D2D2D;
}

.btn-dark{
    background-color: #282A2D;
    color:#FFFFFF;
}


.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: #000;
}

.btn.focus, .btn:focus {
  box-shadow: rgba(0, 123, 255, 0.25) 0px 0px 0px 0.05rem;
  outline: 0px;
}