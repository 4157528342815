#main {
    margin-top: 60px;
    margin-left: 200px;
    padding: 30px 20px;
    transition: all 0.3s;
}

.row.innerCard{
    float: right;
    width: 330px;
    margin: 10px;
}

.cardTitle{
    font-family: "Poppins";
    font-weight:700;
    font-style:normal;
    font-size: 35px;
    color: #313131;
}

.cardTitleDesc{
    font-family: "Poppins";
    font-weight:500;
    font-style:normal;
    font-size: 20px;
    color: #323232;
}
