#main {
    margin-top: 60px;
    margin-left: 200px;
    padding: 30px 20px;
    transition: all 0.3s;
}

.dashBoardTitle{
    font-family: "Poppins";
    font-weight:800;
    font-style:normal;
    font-size: 26px;
    color: #2D2D2D;
}

.dateText{
    font-family: "Poppins";
    font-weight:400;
    font-style:normal;
    font-size: 16px;
    color: #72777B;
}