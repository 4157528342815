.editIcon, .deleteIcon{
    cursor:pointer;
}

.editIcon{
    margin-right: 40px;
}

.inputTable{
    height:20px;
    width: 80%;
    padding-inline: 5px;
}

.saveButton{
    width: 90%;
    padding-inline: 5px;
    padding-block: 1px;
    border-radius: 5px;
}

.badge{
    background-color: #00A2B9;
}

.column-head{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 12px;
    color: #2D2D2D;
}

.column-head-fade{
    font-family: "Poppins";
    font-weight:500;
    font-style:normal;
    font-size: 10px;
    color: #2D2D2D;
}

.column-1{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 12px;
    color: #000000;
}

.column-2{
    font-family: "Poppins";
    font-weight:400;
    font-style:normal;
    font-size: 10px;
    color: #000000;
}

.column-3{
    font-family: "Poppins";
    font-weight:400;
    font-style:normal;
    font-size: 10px;
    color: #000000;
}

.column-4{
    font-family: "Poppins";
    font-weight:500;
    font-style:normal;
    font-size: 12px;
    color: #000000;
}

tr {
    border-bottom: 1px solid #ddd;
}

.table-pagination{
    display: flex;
    justify-content: flex-end;
    padding-right: 4%;
}


.page-item{
    border:none;
    margin-inline:2px;
}

.page-link{
    padding-inline: 13px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    color:#020617;
}

.page-item.disabled .page-link{
    background-color: #fff;
}

.page-item.active .page-link{
    background-color: #fff;
    border-width: 1px;
    border-color: #2D2D2D;
    border-radius: 5px;
    color:#2D2D2D
}

.page-link:active, .page-link:focus, .page-link:checked{
    box-shadow: none;
    border-color: #aeabab;
    outline: 0px;
}