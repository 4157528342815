#register-page{
    height: 100vh;
    width: 100%;
    /* margin: 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FFFFFF;
    background-image: url('../../images/loginBg.png'); /* Adjust the path to your image */
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
}


.company-logo{
    position: relative;
    margin-block: 40px;
}

.footerRow{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: #4F566B;
    width: 500px;
    float:left;
    margin-block: 20px;
    padding-inline-start: 10px;
}

.footer-link{
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;
    color: #697386;
    margin-right: 5px;
}

.footer-link-signup{
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;
    color: #00A2B9;
    margin-left: 5px;
}