/* .card.login{
    width:fit-content;
    position: absolute;
    max-width: 500px;
    border-radius: '5px';
} */

.card.login {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center; 
    height:fit-content;
    max-width: 500px;
    border-radius: '5px';
    padding-inline: 60px;
    padding-block: 20px;
  }

.nav{
    display: flex;
    flex-wrap: nowrap;
}


.nav-tabs .nav-link{
    width: 249.2px;
    border-radius: 0px 0px 0px 0px;
    color: #000000;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;
}

.nav-item .nav-link{
    background-color: #F1F1F1;
}

.form{
    margin: 20px;
}

.FormTitle{
    font-family: "Poppins";
    font-weight: 600;
    font-size: 24px;
    color: #3C4257
}

.form-label{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    color: #3C4257;
}

.form-check-label{
    font-family: "Poppins";
    font-weight: 400;
    font-size: 10px;
    color: #3C4257;
}