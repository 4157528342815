.form-header{
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    margin-inline: 2px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
}

.form-heading{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 20px;
    color: #2D2D2D;
    margin-bottom: 30px;
    width: 200px;
}

.col-form-label{
    padding: 0px;
}
.button-add{
    width: 166px;
    height: 44px;
    border-radius: 5px;
    padding: 7px 15px 7px 15px;
    background-color: #81e0ef;
    border: 0;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
}

.button-add.active{
    background-color: #00A0B7;
}

.inputCardTitle{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 12px;
    color: #2D2D2D;
    margin-bottom: 30px;
}

.inputPlaceHolder{
    font-family: "Poppins";
    font-weight:300;
    font-style:normal;
    font-size: 12px;
    color: #2D2D2D;
}

.inputLabel{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 12px;
    color: #000000;
}

.report-input-heading{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 12px;
    color: #2D2D2D;
    margin-block: 10px;
    margin-inline-start: 10px;
    display: flex;
    justify-content: left;
}

.form-control{
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;
    color: #1D1B20;
}


.form-control:active,.form-control:focus, .form-control:checked{
    box-shadow: none;
    border-color: #aeabab;
    outline: 0px;
}

.dropdown-item{
    height: 20px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 12px;
    color: #49454F;
}