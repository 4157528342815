.innerCard-title-fade{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 16px;
    color: #8B8B8B;
}

.innerCard-title-bold{
    font-family: "Poppins";
    font-weight:700;
    font-style:normal;
    font-size: 18px;
    color: #000000;
    margin-top: 35px;
}

.inner-card-description{
    font-family: "Poppins";
    font-weight:400;
    font-style:normal;
    font-size: 12px;
    color: #000000;
}

.inner-cardVal{
    font-family: "Poppins";
    font-weight:600;
    font-style:normal;
    font-size: 35px;
    color: #00A0B7;
    float: right;
}

.inner-cardUnit{
    font-family: "Poppins";
    font-weight:700;
    font-style:normal;
    font-size: 30px;
    color: #BDBDBD;
    float: right;
}

.inner-card-icon{
    float:right;
    margin-bottom: 5px;
}
